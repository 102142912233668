import {useRef, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import jsQR from 'jsqr';
import '../../../styles/qr.scss';

const Scanner = props => {
  const {p, detectedCode} = props;
  const stream = useRef(null);
  const reqFrame = useRef(null);
  const video = useRef(null);
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  const [currentCode, setCurrentCode] = useState(false);
  const scan = () => {
    if (video.current) {
      context.drawImage(video.current, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);
      if (code) {
        let a = code.data.split('/');
        a = parseInt(a[a.length - 1], 10);
        setCurrentCode(a);
      } else {
        setCurrentCode(false);
      }
    }
    reqFrame.current = requestAnimationFrame(scan);
  };

  useEffect(() => {
    if (currentCode) {
      detectedCode(currentCode);
    }
  }, [currentCode]);
  const scanStream = () => {
    canvas.width = 320;
    canvas.height = 240;
    scan();
  };

  const getVideo = async () => {
    const constraints = {
      video: {
        facingMode: {
          exact: 'environment'
        }
      }
    };
    if (!isMobile || window.location.hostname === 'localhost') {
      constraints['video'] = true;
    }

    try {
      let s = await navigator.mediaDevices.getUserMedia(constraints);
      video.current.srcObject = s;
      scanStream();
    } catch (err) {
      /* handle the error */
    }
  };

  useEffect(() => {
    return () => {
      if (reqFrame.current) {
        const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame;
        cancelAnimationFrame(reqFrame.current);
      }
      if (stream.current) {
        stream.current.gettracks().forEach(track => track.stop());
        stream.current = null;
      }
    };
  }, []);
  useEffect(() => {
    if (video.current) {
      getVideo();
    }
  }, [video.current]);

  return (
    <div className='videoHolder' style={{width: 250 * p, height: 250 * p}}>
      <video
        style={{width: 350 * p, height: 350 * p}}
        ref={video}
        width='320'
        height='320'
        muted
        autoPlay
        playsInline></video>
    </div>
  );
};

export default Scanner;
