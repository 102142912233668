import '../../styles/policy.scss';
const Policy = () => {
  return (
    <div className='policy'>
      <h1>Integritetspolicy</h1>
      <div className='content'>
        <p>
          När du anförtror Outdoor Enterprise Sweden AB dina personuppgifter, så är det viktigt för
          oss att du känner dig trygg. Vi värnar om din personliga integritet. Dina personuppgifter
          behandlas med största respekt och i enlighet med gällande lagstiftning. Denna policy
          beskriver hur vi samlar in och behandlar dina personuppgifter när du besöker vår webbplats
          och använder de tjänster som erbjuds där.
        </p>
        <p>
          All personuppgiftsbehandling sker i enlighet med dataskyddsförordningen.
          Personuppgiftsansvarig är Outdoor Enterprise Sweden AB (org-nr 556338-8056).
        </p>
        <p>
          I samband med att du registrerar din e-post i vår nyhetsbrevsfunktion, eller på annat sätt
          kommunicerar uppgifter till oss, godkänner du att vi sparar och använder dina uppgifter
          för att fullfölja och tillhandahålla den service som du kan förvänta dig av oss.
        </p>
        <ol className='flex flex-col gap-5'>
          <li className='flex flex-col gap-1'>
            <strong>Vad är en personuppgift? </strong>
            <p>
              Personuppgifter är information och data som direkt eller indirekt kan hänföras till en
              fysisk person som är i livet. Exempel på personuppgifter är namn, personnummer,
              adress, e-post adress och telefonnummer. Det kan också gälla kundnummer, krypterade
              uppgifter och olika slags elektroniska identiteter som exempelvis IP-nummer.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>När gäller detta integritetsmeddelande? </strong>
            <p>
              Detta integritetsmeddelande gäller all behandling av personuppgifter i samband med all
              vår affärsverksamhet inom alla våra affärsområden. Meddelandet är tillämpligt på
              behandling av både historiska och framtida personuppgifter. Observera att separata
              integritetsmeddelanden kan gälla exempelvis för anställda, sökanden samt deltagare i
              handelsmässor, jobbmässor och liknande evenemang.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>Vilka personuppgifter behandlar vi och i vilka syften? </strong>Outdoor
            Enterprise Sweden AB får behandla personuppgifter i följande situationer och i följande
            syften:
            <p>
              <strong>Kommunikation:</strong>Vi får behandla personuppgifter när du kontaktar oss
              eller när vi kontaktar dig. T.ex. när du kontaktar vår kundtjänst eller skriver eller
              ringer till oss. I sådana fall får vi i regel behandla namn och kontaktuppgifter,
              liksom innehåll och tidpunkt för relevanta meddelanden. Vi får använda dessa uppgifter
              för att förse dig med information, behandla din begäran och kommunicera med dig. Vi
              kan även vidarebefordra meddelanden inom Beretta-koncernen, t.ex. om din begäran avser
              en annan enhet inom vår koncern.
            </p>
            <p>
              <strong>Besök på webbplatser, användande av appar:</strong> När du besöker någon av
              våra webbplatser, eller installerar och använder appar från oss, så får vi automatiskt
              samla in information om din webbläsare, datorns ip-adress, din internetleverantör,
              platsen från vilken du navigerade till vår webbplats, hur länge besöket på webbplatsen
              varar och vilken typ av enhet du använder (t.ex. dator, smarttelefon eller datorplatta
              samt respektive operativsystem.)
            </p>
            <p>
              Vi får även registrera de sidor som du tittar på under besöket. Denna information får
              användas för att förbättra webbplatsens funktionssätt, för statistiska ändamål och för
              systemförvaltning. Vi får även använda ”kakor”, små textfiler som tillfälligt eller
              permanent lagras på din enhet när du besöker webbplatsen. Kakorna samlar in
              information om antalet besökare på webbplatsen, vilka sidor som besöks och den tid som
              besöken varar. De är många gånger nödvändiga för att webbplatsen ska fungera på ett
              bra sätt. Andra kan användas för att anpassa erbjudanden eller för att Outdoor
              Enterprise Sweden AB ska kunna visa riktad reklam från tredje part. Du kan välja bort
              kakor genom att välja lämpliga inställningar i din webbläsare. Detta kan dock påverka
              hur du upplever webbplatsen.
            </p>
            <p>
              Vi får också använda analystjänster som tillhandahålls av tredjepartsleverantör, t.ex.
              men ej begränsat till Google Analytics och liknande verktyg. Som en del av dessa
              tjänster samlar tjänsteleverantören in information om hur relevant webbplats används,
              dock ofta på ett sätt där enskilda personer inte identifieras.
            </p>
            <p>
              Slutligen får vi använda funktioner från leverantörer såsom t.ex. men ej begränsat
              till Facebook, Instagram, YouTube, Brevo och Salesforce, vilket kan leda till att
              relevant leverantör behandlar uppgifter om dig. Vi rekommenderar starkt att du läser
              dessa externa tjänsteleverantörers integritetsmeddelanden.
            </p>
            <p>
              <strong>E-post användning:</strong> Vi får använda ditt namn och din e-post adress för
              att skicka meddelanden, uppdateringar, inbjudningar till evenemang, kampanjer och
              annan information till dig. Vi kommer emellertid att först be om ditt samtycke, om vi
              inte fått dina kontaktuppgifter inom ramen för tillhandahållandet av våra tjänster. Om
              du inte längre vill ta emot marknadsföringskommunikation från oss, kan du när som
              helst avregistrera dig med hjälp av länken i e-post meddelandena. Vi kan komma att
              använda tredjepartsleverantörer för att få information om du öppnar dina meddelanden
              och om du klickar på länkarna i dem. Du kan välja bort denna möjlighet med hjälp av
              lämpliga inställningar i ditt e-post program.
            </p>
            <p>
              <strong>Nyhetsbrev:</strong> På vår webbplats används vidare en nyhetsbrevsfunktion
              från Brevo. Vid registrering till vårt nyhetsbrev, så lämnar du automatiskt ditt
              samtycke till att vi får registrera de uppgifter som du ger oss via tjänsten. Därtill
              lämnar du ditt samtycke att vi även får använda dessa uppgifter för att skicka
              meddelanden, uppdateringar, inbjudningar till evenemang, kampanjer och annan
              information till dig. Om du inte längre vill ta emot marknadsföringskommunikation från
              oss, kan du när som helst avregistrera dig med hjälp av länken i e-post meddelandena.
            </p>
            <p>
              <strong>Besök i våra lokaler:</strong> När du besöker våra lokaler kan vi komma att
              göra videoinspelningar i på lämpligt sätt markerade områden i säkerhets- och
              dokumentationssyfte. Det kan hända att du kan använda en wi-fi tjänst. I detta fall
              samlar vi under registreringen in enhetsspecifika uppgifter, och vi kan be dig ange
              namn och e-post adress när du registrerar dig.
            </p>
            <p>
              <strong>Kundevenemang:</strong> När vi anordnar kundevenemang (evenemang om reklam,
              sponsring, kultur eller idrott) får vi också behandla personuppgifter. Sådana
              uppgifter kan inbegripa deltagarnas eller de berörda parternas namn och adress liksom
              andra evenemangsspecifika uppgifter, såsom födelsedatum. Vi får behandla denna
              information i syfte att genomföra kundevenemangen, men också för att få direktkontakt
              med dig och lära känna dig bättre.
            </p>
            <p>
              <strong>Affärspartner:</strong> Outdoor Enterprise Sweden AB arbetar tillsammans med
              olika företag och affärspartner, till exempel leverantörer, kommersiella kunder av
              varor och tjänster och tillhandahållare av tjänster (t.ex. it-leverantörer). Vi får
              behandla personuppgifter om kontaktpersonerna vid dessa företag, t.ex. deras namn och
              befattning. Beroende på verksamhetsområde måste vi även i detalj granska relevant
              företag och/eller dess anställda. Om detta är aktuellt kommer vi att meddela dig
              särskilt. Vi får också behandla personuppgifter för att förbättra vår kundorientering,
              kundnöjdhet och kundlojalitet (kundhantering).
            </p>
            <p>
              <strong>Administration:</strong> Vi får behandla personuppgifter för vår interna och
              gruppinterna administration. Vi får exempelvis behandla personuppgifter inom ramen för
              it- eller fastighetsförvaltning. Vi får också behandla personuppgifter i redovisnings-
              och arkiveringssyfte, och allmänt för att kontrollera och förbättra interna processer.
            </p>
            <p>
              <strong>Företagstransaktioner:</strong> Vi får även behandla personuppgifter för att
              förbereda och genomföra företagstransaktioner och andra transaktioner.
            </p>
            <p>
              <strong>Jobbansökningar:</strong> Vi får även behandla dina personuppgifter när du
              söker jobb hos oss. Som en allmän regel efterfrågar vi gängse information och
              handlingar, liksom de som anges i jobbannonsen. För information om hur vi behandlar de
              personuppgifter som samlas in i samband med en specifik jobbansökan, vänligen kontakta
              oss på nedan angivna kontaktuppgifter.
            </p>
            <p>
              <strong>Anställning:</strong> Vi behandlar våra anställdas personuppgifter inom ramen
              för deras anställning. Ett särskilt integritetsmeddelande för anställda är tillämpligt
              i detta syfte.
            </p>
            <p>
              <strong>Uppfyllande av rättsliga krav:</strong> Vi får behandla personuppgifter för
              att uppfylla rättsliga krav. Här ingår exempelvis upprätthållandet av systemet för
              visselblåsare för rapportering av eventuella missförhållanden, interna utredningar
              eller utlämnande av handlingar till en myndighet, om vi har goda skäl för detta eller
              till och med är skyldiga att göra detta enligt lag. I detta sammanhang får vi behandla
              namn och dokumentation eller redogörelser om dig eller en tredje part.
            </p>
            <p>
              <strong>Skydd av rättigheter:</strong>Vi får behandla personuppgifter i olika
              kombinationer för att skydda våra rättigheter, till exempel för att hävda anspråk i
              och utanför domstol och inför nationella och utländska myndigheter, eller för att
              försvara oss gentemot anspråk. Vi får till exempel förtydliga prospekt eller skicka in
              handlingar till en myndighet. Myndigheter kan också kräva att vi lämnar ut handlingar
              som innehåller personuppgifter.
            </p>
            <p>
              Vi behandlar personuppgifter på följande grunder: - För fullgörande av avtal. <br />-
              För legitima intressen. Detta inbegriper till exempel intresset av kundvård och
              kommunikation med kunder utanför ett avtal, av marknadsföring, av att lära känna våra
              kunder och andra bättre, av att förbättra våra produkter och tjänster och utveckla
              nya, av att bekämpa bedrägerier och förebygga och utreda överträdelser, av att skydda
              kunder, anställda och andra personer liksom Outdoor Enterprise Swedens uppgifter,
              hemligheter och tillgångar, av att garantera it-säkerhet, särskilt i anslutning till
              användning av webbplatser, appar och annan it-infrastruktur, av att garantera och
              organisera affärsverksamheten, däribland driften och den vidare utvecklingen av
              webbplatser och andra system, av företagets förvaltning och utveckling, av försäljning
              eller köp av företag, delar av företag eller andra tillgångar, och av verkställande
              eller försvar av rättsliga anspråk. <br />- För information om produkter och tjänster.
              Vi använder dina personuppgifter för e-postutskick med information om våra produkter
              och tjänster. Du kan när som helst återkalla ditt samtycke till mottagandet av
              nyhetsbrev, kampanjer och erbjudanden genom att kontakta kundservice eller använda den
              avanmälningslänk som finns i varje nyhetsbrev. <br />- Baserat på ett samtycke, om
              detta samtycke har erhållits särskilt. <br />- För uppfyllande av rättsliga och
              lagstadgade skyldigheter.
            </p>
            <p>
              Du är i regel inte skyldig att lämna dina personuppgifter till oss. Vi måste
              emellertid samla in och behandla vissa uppgifter för att kunna ingå och fullgöra avtal
              och för andra ändamål.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>Vem delar vi din personliga information med?</strong>
            <p>
              Våra anställda har tillgång till dina personuppgifter i den mån det är nödvändigt för
              de angivna syftena och de berörda medarbetarnas arbete. De agerar i enlighet med våra
              anvisningar och är bundna av sekretess och tystnadsplikt när de hanterar
              personuppgifterna.
            </p>
            <p>
              Vi får också överföra dina personuppgifter till andra juridiska enheter inom
              Beretta-koncernen för intern gruppadministration och för de olika behandlingsändamål
              som beskrivs i detta integritetsmeddelande. Detta innebär att personuppgifterna kan
              komma att behandlas och kombineras med personuppgifter från andra juridiska enheter
              inom Beretta-gruppen för respektive ändamål.
            </p>
            <p>
              Vi får även lämna ut dina personuppgifter till tredjepartsleverantörer som bedriver
              viss affärsverksamhet för vår räkning (”behandlare”), i synnerhet vad gäller
            </p>
            <p>
              - IT-tjänster, t.ex. datalagring, molntjänster, dataanalys etc., <br />-
              konsulttjänster, t.ex. skatterådgivare, advokater, ledningskonsulter, rekrytering
              etc.,
              <br />- logistik för varuleveranser, <br />- administrativa tjänster, t.ex.
              fastighetsförvaltning, <br />- affärsinformation och indrivning av fordringar.
              <br />
            </p>
            <p>
              Det finns andra fall när vi får lämna ut dina personuppgifter, exempelvis följande:
            </p>
            <p>
              - Vi får lämna ut dina personuppgifter till tredje part (t.ex. myndigheter) om detta
              erfordras enligt lag. Vi förbehåller oss också rätten att behandla dina
              personuppgifter för att följa ett domstolsbeslut eller för att hävda eller försvara
              rättsliga anspråk, eller om vi anser det vara nödvändigt av andra rättsliga skäl.
            </p>
            <p>
              - Vi får lämna ut dina personuppgifter till tredje part (t.ex. förvärvare) om detta
              behövs för en företagstransaktion.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>När lämnar vi ut dina personuppgifter till tredjeländer? </strong>
            <p>
              Mottagarna av dina personuppgifter (avsnitt 4) kan vara belägna utomlands, också i
              länder utanför EU och EES som kanske inte har lagstiftning som skyddar
              personuppgifterna i samma utsträckning som lagstiftningen i EU och EES. Om vi lämnar
              ut personuppgifter till en mottagare i ett sådant land ingår de vanligtvis i ett avtal
              om dataöverföring för att säkerställa ett adekvat skydd av uppgifterna, inbegripet
              avtal. Kontakta oss på nedanstående kontaktuppgifter om du behöver mer information i
              detta hänseende.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>Hur skyddar vi dina personuppgifter? </strong>
            <p>
              Vi följer lämpliga tekniska och organisatoriska säkerhetsprocesser för att skydda dina
              personuppgifter mot obehörig eller olaglig behandling och för att förebygga förlust,
              oavsiktlig ändring, oavsiktligt röjande och obehörig åtkomst.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>Hur länge sparar vi dina personuppgifter?</strong>
            <p>
              Om du har gett ditt samtycke till att Outdoor Enterprise Sweden AB behandlar dina
              personuppgifter kan du återkalla detta samtycke. Du har också rätt att lämna in
              klagomål till en dataskyddsmyndighet om hur vi har använt dina personuppgifter.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>Vilka rättigheter har du när det gäller dina personuppgifter? </strong>
            <p>
              Du har följande rättigheter inom ramen för de begränsningar som fastställs i
              tillämplig lagstiftning: Du kan t.ex. begära att få tillgång till de av dina
              personuppgifter som behandlas av oss, att korrigera eller radera uppgifterna, att de
              personuppgifter som du lämnat till oss lämnas tillbaka till dig eller överförs till en
              person som du väljer, i ett strukturerat, allmänt använt och maskinläsbart format.
            </p>
            <p>
              Om du har gett ditt samtycke till att Outdoor Enterprise Sweden AB behandlar dina
              personuppgifter kan du återkalla detta samtycke. Du har också rätt att lämna in
              klagomål till en dataskyddsmyndighet om hur vi har använt dina personuppgifter.
            </p>
          </li>
          <li className='flex flex-col gap-1'>
            <strong>Kontaktuppgifter</strong>
            <p>
              Om du har frågor eller vill utöva dina rättigheter med avseende på behandlingen av
              dina personuppgifter, kontakta dataskyddsgruppen på{' '}
              <a className='text-orange-700' href='mailto:info@outdoor-enterprise.se'>
                info@outdoor-enterprise.se
              </a>
              . Märk ditt e-post med ”Att: Dataskyddsgruppen” i ärendefältet i ditt e-post för att
              ärendet skall hanteras korrekt.
            </p>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Policy;
