import {createRef, useRef, useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';
import withRouter from '../global/withRouter';
import {useNavigate} from 'react-router-dom';
import Lottie from 'lottie-react';
import gameLogo from '../../assets/qr/qr_logo.svg';
import quiz from '../../assets/quiz.svg';
import logo from '../../assets/logo.json';
import '../../styles/start.scss';

const Start = () => {
  const navigate = useNavigate();
  const logoRef = useRef(null);
  const [introDone, setIntroDone] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailValid, setEmailValid] = useState(false);

  const validateEmail = e => {
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    setEmail(e.target.value);
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  useEffect(() => {
    if (logoRef?.current && !introDone) {
      logoRef.current.setSpeed(2);
      logoRef.current.play();
      setTimeout(() => {
        setIntroDone(true);
      }, 1500);
    }
  }, [logoRef]);

  return (
    <div className='start'>
      <div className={isMobile ? 'logoHolder smaller' : 'logoHolder'}>
        <Lottie
          lottieRef={logoRef}
          className='logo'
          loop={false}
          autoplay={false}
          animationData={logo}
        />
      </div>
      <div className={!introDone ? 'content-holder' : 'content-holder show'}>
        <div className='content'>
          <h1>
            Tävlingarna är <br />
            avslutade.
          </h1>
          {/*<div className='comp'>
            <img src={quiz} />
            <h2>Vinn ett Burris Eliminator6</h2>
            <p>
              Svara på tre frågor och vinn ett <b>Burris Eliminator6</b> kikarsikte med
              laseravståndsmätare och kulbanekompensator, nya linser bättre prestanda och helt ny
              elegant design.
            </p>
            <div
              className='btn'
              onClick={() => {
                navigate('/quiz');
              }}>
              STARTA
            </div>
          </div>*/}
          {/*<div className='comp'>
            <img src={gameLogo} />
            <p>
              Hitta fem speciella QR-koder i vår monter
              <br /> på <b>Swedish Game Fair</b> och tävla om fina priser varje dag.
            </p>
            <div
              className='btn'
              onClick={() => {
                navigate('/qr');
              }}>
              STARTA
            </div>
          </div>*/}
          {/*<div className='comp'>
            <h2>TESTSKJUT OCH VINN EN BERETTA BRX1</h2>
            <p>
              När du testat Beretta BRX1 på skjutbanan får du scanna en specifik QR-kod och anmäla
              dig via länken. Du har då möjlighet att vinna en BRX1.
            </p>
            <h2 className='center' style={{marginTop: 20, fontWeight: 'bold'}}>
              Vad väntar du på?
              <br />
              Spring över till skjutbanan!
            </h2>
          </div>*/}
          <div className='comp black center'>
            <h2>ANMÄL DIG GÄRNA TILL VÅRT NYHETSBREV</h2>
            <p>
              Var först med det senaste – prenumerera på vårt nyhetsbrev så du inte missar något av
              nyheterna från våra härliga varumärken.
            </p>
            <div
              className='btn'
              onClick={() => {
                navigate('/newsletter');
              }}>
              ANMÄL DIG NU!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Start);
