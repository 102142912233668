import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/App.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {isMobile} from 'react-device-detect';
import {CookiesProvider} from 'react-cookie';
import {BrowserRouter} from 'react-router-dom';

if (isMobile) {
  document.body.classList.add('Mobile');
} else {
  document.body.classList.remove('Mobile');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <CookiesProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
