import {createRef, useRef, useEffect, useState} from 'react';

import {isMobile} from 'react-device-detect';
import {useLockBodyScroll} from '@uidotdev/usehooks';
import withRouter from '../global/withRouter';
import {chunk, find, includes} from 'lodash';
import {gsap} from 'gsap';
import {TweenMax, TimelineMax} from 'gsap/all';
import jsQR from 'jsqr';
import {Get, Post} from '../../data/api';
import Scanner from './modules/Scanner';
import Onboarding from './modules/Onboarding';
import HelpOverlay from './modules/HelpOverlay';
import logo_pic from '../../assets/logo.svg';
import gameLogo from '../../assets/qr/qr_logo.svg';
import {Swiper, SwiperSlide} from 'swiper/react';
import {withCookies, Cookies} from 'react-cookie';
import moment from 'moment-timezone';
//import Explosion from 'react-canvas-confetti/dist/presets/explosion';
import ReactCanvasConfetti from 'react-canvas-confetti/dist';
import Pride from 'react-canvas-confetti/dist/presets/pride';
import randomInRange from 'react-canvas-confetti/dist/helpers/randomInRange.js';
import 'swiper/css';
import 'swiper/css/effect-cards';

import {EffectCards} from 'swiper/modules';
import TC from '../global/TC';

import '../../styles/qr.scss';
const activated = [TweenMax, TimelineMax];

let comp_to = 844;
let p = window.innerHeight / 844;

const Qr = props => {
  const {ownProps, cookies} = props;

  useLockBodyScroll();

  const [showOnboarding, setShowOnboarding] = useState(true);
  const [openHelp, setOpenHelp] = useState(false);
  const [coinList1, setCoinList1] = useState([]);
  const [coinList2, setCoinList2] = useState([]);
  const [cookiesAccepted, setCookiesAccepted] = useState(-1);
  const [showTC, setShowTC] = useState(false);

  const [coinsLoaded, setCoinsLoaded] = useState(false);

  const [qrRefs, setQrRefs] = useState([]);
  const [collected, setCollected] = useState([1, 1, 2, 1, 1]);

  const [loaded, setLoaded] = useState(false);
  const [gameDone, setGameDone] = useState(false);
  const s1 = useRef(false);
  const s2 = useRef(false);
  const detected = useRef('');
  const explode = useRef();

  const storedList = useRef([]);
  const intCheck = useRef(null);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [max, setMax] = useState(0);
  const [emailValid, setEmailValid] = useState(false);
  const [allDone, setAllDone] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [formDone, setFormDone] = useState(false);

  const commonOptions = {
    spread: 120,
    ticks: 30,
    gravity: 0,
    decay: 0.94,
    startVelocity: 20,
    colors: ['#ea580c', 'ff0000', '#FFE400', '#fff'],
    particleCount: 60,
    shapes: ['circle']
  };
  const decorateOptions = defaultOptions => {
    return {
      ...defaultOptions,
      colors: ['#ea580c', '#fff', '#000'],
      scalar: randomInRange(0.5, 1.5),
      particleCount: randomInRange(1, 10)
    };
  };
  const angles = [90, 0, -90, -180];
  const doubleAngles = angles.concat(angles);

  const validateEmail = e => {
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    setEmail(e.target.value);
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const fire = () => {
    doubleAngles.forEach((angle, index) => {
      setTimeout(() => {
        explode.current({
          ...commonOptions,
          angle
        });
      }, index * 100);
    });
  };
  const onInit = ({confetti}) => {
    explode.current = confetti;
  };

  const fetchQrs = () => {
    if (coinList1.length === 0) {
      Get('getQrs')
        .then(indata => {
          if (indata?.data?.data) {
            let c1 = indata.data.data;
            let c2 = [];
            if (indata.data.data.length > 5) {
              let ch = chunk(indata.data.data, 5);
              c1 = ch[0];
              c2 = ch[1];
            }

            let refs = new Array(indata.data.data.length).fill().map(() => {
              return createRef();
            });
            setQrRefs(refs);
            setMax(refs.length);
            console.log('loaded data');
            storedList.current = indata.data.data;
            setCoinList1(c1);
            setCoinList2(c2);
            setLoaded(true);
          }
        })
        .catch(err => {
          console.log(err);
          setLoaded(true);
        });
    }
  };

  useEffect(() => {
    if (coinsLoaded && storedList.current.length > 0 && cookiesAccepted === 2) {
      let isCollected = [...collected];
      if (ownProps?.qr_code && !includes(collected, parseInt(ownProps?.qr_code, 10))) {
        let found = find(storedList.current, q => q.qnr === parseInt(ownProps.qr_code, 10));
        if (found) {
          let col = [...collected];
          col.push(parseInt(ownProps?.qr_code, 10));
          isCollected = col;
          setCollected(col);
          setCookie(col);
          activateCoin(found.id);
        }
      }

      if (isCollected.length > 0) {
        isCollected.map(col => {
          let found = find(storedList.current, q => parseInt(q.qnr, 10) === parseInt(col, 10));
          if (found) {
            if (qrRefs[found.id - 1].current) {
              let child = qrRefs[found.id - 1].current;
              let cls = child.children[0].className;
              if (cls.indexOf('nan') > -1) {
                cls = cls.replace('nan', '');
              }
              cls += ' active';
              child.children[0].className = cls;
            }
          }
        });
      }
    }
  }, [coinsLoaded, storedList.current, cookiesAccepted]);

  const checkCookies = () => {
    let expo = cookies.get('expo');
    if (expo === 2) {
      if (intCheck.current) {
        clearInterval(intCheck.current);
      }
      setCookiesAccepted(2);
      start();
    } else if (expo === 1) {
      setCookiesAccepted(1);
      if (intCheck.current) {
        clearInterval(intCheck.current);
      }
    }
  };

  const start = () => {
    let allC = cookies.getAll();
    if (!allC?.collected) {
      setCookie([]);
      setCollected([]);
      fetchQrs();
    } else {
      let c = cookies.get('collected');
      if (c && c !== undefined) {
        detected.current = c.join(', ');
        console.log('setCollected', c);
        setCollected(c);
        setShowOnboarding(false);
        fetchQrs();
      }
    }
  };

  useEffect(() => {
    let expo = cookies.get('expo');
    if (expo === 2) {
      setCookiesAccepted(2);
      start();
    } else if (expo === 1) {
      setCookiesAccepted(1);
    } else {
      checkCookies();
      intCheck.current = setInterval(() => {
        checkCookies();
      }, 1000);
    }

    return () => {
      if (intCheck.current) {
        clearInterval(intCheck.current);
      }
    };
  }, []);

  useEffect(() => {
    if (collected.length >= max && max > 0) {
      setTimeout(() => {
        gsap.to('.swiper', {duration: 1, scale: 0, opacity: 0, ease: 'elastic.inOut'});
        if (isMobile) {
          gsap.to('.infoHolder', {position: 'absolute', scale: 0.6, duration: 1});
        } else {
          gsap.to('.infoHolder', {position: 'absolute', duration: 1});
        }
        gsap.to('.center', {
          duration: 1,
          scale: 0,
          opacity: 0,
          ease: 'elastic.inOut',
          onComplete: () => {
            gsap.set('.top', {display: 'none'});
            gsap.set('.bottom', {display: 'none'});
            gsap.set('.swiper', {display: 'none'});
            gsap.to('.allDone', {duration: 1, display: 'flex', opacity: 1});
          }
        });
        setGameDone(true);
      }, 2000);
    }
  }, [collected]);

  const setCookie = val => {
    let found = false;
    let c = cookies.get('collected');
    if (c && c !== undefined) {
      if (includes(c, val)) {
        found = true;
      }
    }
    if (!found) {
      cookies.set('collected', val, {
        path: '/',
        expires: moment().add(1, 'year').toDate()
      });
    }
  };

  const detectedCode = code => {
    if (
      !includes(collected, code) &&
      detected.current.indexOf(code) === -1 &&
      storedList?.current
    ) {
      detected.current += code + ',';

      let found = find(storedList.current, q => q.qnr === parseInt(code, 10));
      if (found) {
        let col = [...collected];
        col.push(parseInt(code, 10));
        setCollected(col);
        setCookie(col);
        activateCoin(found.id);
      }
    }
  };
  const activateCoin = id => {
    if (qrRefs[id - 1]?.current) {
      setTimeout(() => {
        let child = qrRefs[id - 1].current;

        let tl = new TimelineMax();

        gsap.set(child.children[0], {rotateY: 0});
        fire();
        tl.to(child.children[0], 1, {
          rotateX: 360 * 3,
          ease: 'power4.out',
          onUpdate: () => {
            let cls = child.children[0].className;

            if (tl.progress() >= 0.6 && cls.indexOf('active') === -1) {
              if (cls.indexOf('nan') > -1) {
                cls = cls.replace('nan', '');
              }
              cls += ' active';
              child.children[0].className = cls;
            }
          }
        }).add('end');
        tl.timeScale(1).seek(0);
      }, 1200);
    }
  };

  return (
    <>
      <div className='animatedCoin'></div>
      <div className='qr'>
        <div className='topBar'>
          <img className='miniLogo' src={logo_pic} />
          <div
            className='questions'
            onClick={() => {
              setOpenHelp(true);
            }}>
            <img src={require('../../assets/qr/help.png')} />
          </div>
        </div>
        {!showOnboarding && (
          <>
            <div className='infoHolder'>
              <div className={gameDone ? 'content done' : 'content'}>
                <div className='topLogoHolder'>
                  <img src={gameLogo} className='qrlogo' />
                  <div className='counter'>
                    <svg>
                      <text
                        x='50%'
                        y='50%'
                        textAnchor='middle'
                        alignmentBaseline='middle'
                        className='outline'>
                        {collected.length} / {max}
                      </text>
                      <text x='50%' y='50%' textAnchor='middle' alignmentBaseline='middle'>
                        {collected.length} / {max}
                      </text>
                    </svg>
                  </div>
                </div>
              </div>
            </div>

            <div className={gameDone ? 'qrHolder done' : 'qrHolder'}>
              <div className='top'>
                <Swiper
                  effect={'cards'}
                  initialSlide={2}
                  grabCursor={true}
                  onSwiper={e => {
                    s1.current = e;
                    if (s1.current) {
                      setCoinsLoaded(true);
                    }
                  }}
                  cardsEffect={{
                    centeredSlides: true,
                    perSlideOffset: 15,
                    perSlideRotate: 30,
                    slideShadows: false
                  }}
                  modules={[EffectCards]}
                  className='coinSwiper'
                  style={{height: 150 * p}}>
                  {coinList1.map((item, index) => {
                    return (
                      <SwiperSlide
                        ref={qrRefs[index]}
                        key={`coin1_${index}`}
                        style={{width: 120 * p, height: 120 * p}}>
                        <div
                          className={`coin nan _${item.qnr}`}
                          style={{width: 120 * p, height: 120 * p}}>
                          <img src={require('../../assets/qr/neee.png')} />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
              {gameDone && (
                <div className='allDone'>
                  {formDone && (
                    <div className='formDone'>
                      <img src={logo_pic} />
                      <p>
                        Tack! <br />
                        Du är nu med i våran tävling!
                      </p>
                    </div>
                  )}
                  {!formDone && (
                    <>
                      <h2>
                        Fyll i dina uppgifter
                        <br />
                        Och var med i tävlingen!
                      </h2>
                      <input
                        type='text'
                        placeholder='Namn'
                        value={name}
                        className='pressable'
                        onChange={e => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type='text'
                        placeholder='Din@epostadress.se'
                        value={email}
                        onChange={validateEmail}
                        className={!emailValid ? 'notvalid pressable' : 'pressable'}
                      />
                      <input
                        type='text'
                        className='pressable'
                        placeholder='Mobilnummer'
                        value={phone}
                        onChange={e => {
                          let str = e.target.value;
                          str = str.replace(/\D/g, '');
                          setPhone(str);
                        }}
                      />
                      <label className='checkboxHolder'>
                        <input
                          type='checkbox'
                          checked={accepted}
                          onChange={e => {
                            setAccepted(e.target.checked);
                          }}
                          className='pressable'
                        />
                        <p>
                          Jag godkänner{' '}
                          <span
                            className='subline pressable'
                            onClick={() => {
                              setShowTC(true);
                            }}>
                            tävlingsvillkoren
                          </span>{' '}
                          samt att jag vill ha nyhetsbrev.
                        </p>
                      </label>
                      <div
                        className={
                          name.length >= 2 &&
                          phone.length >= 10 &&
                          emailValid &&
                          email.length > 4 &&
                          accepted
                            ? 'btn active pressable'
                            : 'btn pressable'
                        }
                        onClick={() => {
                          if (
                            name.length >= 2 &&
                            phone.length >= 10 &&
                            emailValid &&
                            email.length > 4 &&
                            accepted
                          ) {
                            Post('saveQrData', {
                              data: {name, phone, email, newsletter: accepted}
                            })
                              .then(data => {
                                setFormDone(true);
                                setName('');
                                setEmail('');
                                setPhone('');
                                setEmailValid(false);
                              })
                              .catch(err => {
                                console.log('err', err);
                              });
                          }
                        }}>
                        Skicka in
                      </div>
                    </>
                  )}
                </div>
              )}
              <div className='center'>
                {!gameDone && <Scanner p={p} detectedCode={detectedCode} />}
                <ReactCanvasConfetti onInit={onInit} />
              </div>
              {/*<div className='bott'>
                <Swiper
                  effect={'cards'}
                  grabCursor={true}
                  initialSlide={2}
                  onSwiper={e => {
                    s2.current = e;
                    if (s1.current && s2.current) {
                      setCoinsLoaded(true);
                    }
                  }}
                  cardsEffect={{
                    centeredSlides: true,
                    perSlideOffset: 15,
                    perSlideRotate: 30,
                    slideShadows: false
                  }}
                  modules={[EffectCards]}
                  className='coinSwiper _bott'
                  style={{height: 150 * p}}>
                  {coinList2.map((item, index) => {
                    return (
                      <SwiperSlide
                        ref={qrRefs[5 + index]}
                        key={`coin2_${index}`}
                        style={{width: 120 * p, height: 120 * p}}>
                        <div
                          className={`coin nan _${item.qnr}`}
                          style={{width: 120 * p, height: 120 * p}}>
                          <img src={require('../../assets/qr/neee.png')} />
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>*/}
            </div>
          </>
        )}

        {gameDone && <Pride autorun={{speed: 30}} decorateOptions={decorateOptions} />}
      </div>
      {showOnboarding && (
        <Onboarding setShow={setShowOnboarding} cookiesAccepted={cookiesAccepted} />
      )}
      {!showOnboarding && openHelp && <HelpOverlay showHelp={setOpenHelp} />}
      {showTC && <TC openOverlay={setShowTC} id={2} />}
    </>
  );
};

export default withRouter(withCookies(Qr));
