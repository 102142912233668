import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../styles/help.scss';

const HelpOverlay = props => {
  const {showHelp} = props;

  const ios = /Mobi|iPad|iPhone|iPod/i.test(navigator.userAgent) ? true : false;
  return (
    <div className='help'>
      <div className='help_content'>
        <div
          className='close'
          onClick={() => {
            showHelp(false);
          }}>
          <FontAwesomeIcon icon={faCircleXmark} size='2x' />
        </div>
        <div className='hcontent'>
          <h3>Problem med videon?</h3>
          <p>
            Börja med att säkerställa att du inte redan använder kameran i någon annan app eller
            hemsida.
          </p>
          <b>Nekade du rättigheterna till kameran?</b>
          {!ios && (
            <>
              <p>
                Du kan tillåta eller blockera behörigheter för en viss webbplats. Webbplatsen
                använder de behörigheter du anger i stället för standardinställningarna.
              </p>
              <ol>
                <li>
                  Öppna Chrome på Android-enheten{' '}
                  <img
                    src='//lh3.googleusercontent.com/uSRJIFR8eDSfkfjB4tuohW_Lojbukra5FzyRMzLEz-wRjAKm_9v6NNS2MS49JxrhQ37L=w36-h36'
                    width='18'
                    height='18'
                    alt='Chrome'
                    data-mime-type='image/png'
                  />
                  .
                </li>
                <li>Besök en webbplats.</li>
                <li>
                  Tryck på Visa information om webbplatsen{' '}
                  <img
                    src='//storage.googleapis.com/support-kms-prod/S76Rs1BC1QDxT8zpF3tATLDsc5oxceWYIPHN'
                    width='18'
                    height='18'
                    alt='Default (Secure)'
                    data-mime-type='image/svg+xml'
                  />{' '}
                  till vänster om adressfältet{' '}
                  <img
                    src='//lh3.googleusercontent.com/3_l97rr0GvhSP2XV5OoCkV2ZDTIisAOczrSdzNCBxhIKWrjXjHucxNwocghoUa39gw=w36-h36'
                    width='18'
                    height='18'
                    alt='följt av'
                    data-mime-type='image/png'
                  />{' '}
                  <strong>Behörigheter</strong>.
                </li>
                <li>
                  Tryck på behörigheten som du vill uppdatera.
                  <ul>
                    <li>Om du vill ändra en inställning väljer du den.</li>
                    <li>
                      Tryck på <strong>Återställ behörigheter</strong> om du vill radera
                      webbplatsens inställningar.
                    </li>
                    <li>Ladda om sidan</li>
                  </ul>
                </li>
              </ol>
            </>
          )}
          {ios && (
            <Accordion>
              <Accordion.Item eventKey='0'>
                <Accordion.Header>Chrome</Accordion.Header>
                <Accordion.Body>
                  <ol>
                    <li>
                      Öppna Chrome-appen{' '}
                      <img
                        src='https://lh3.googleusercontent.com/uSRJIFR8eDSfkfjB4tuohW_Lojbukra5FzyRMzLEz-wRjAKm_9v6NNS2MS49JxrhQ37L=w36-h36'
                        width='18'
                        height='18'
                        alt='Chrome'
                        data-mime-type='image/png'
                      />{' '}
                      på en iPhone eller iPad.
                    </li>
                    <li>
                      Tryck på Mer{' '}
                      <img
                        src='https://lh3.googleusercontent.com/rdSHV07_eoB7NUi3yZmOlZtCx4_ShLe7Ywukv_AS-PZBX1Hu_FmPQoI89FseERsb-ad9=w36-h36'
                        width='18'
                        height='18'
                        alt='Mer'
                        data-mime-type='image/png'
                      />{' '}
                      <img
                        src='//lh3.googleusercontent.com/M1clyx8CUPwAH1XgxPTgbMx6sSwblyKIw2QAVv1HG_JrI2KCL4aMsSCCwq3zT1X2bm_n=w36-h36'
                        width='18'
                        height='18'
                        alt='följt av'
                        data-mime-type='image/png'
                      />{' '}
                      Inställningar{' '}
                      <img
                        src='//lh3.googleusercontent.com/iPnriswGNbEMKn0vKE-pyaRutZ7ZmcgtSdH6IkN0tAP-6NqDZ_Teg9bGOqIBdI6dpXY=w36-h36'
                        width='18'
                        height='18'
                        alt='Inställningar'
                        data-mime-type='image/png'
                      />
                      .
                    </li>
                    <li>
                      Tryck på <strong>Innehållsinställningar</strong>.
                    </li>
                    <li>Tryck på behörigheten som du vill uppdatera.</li>
                    <li>ladda om sidan</li>
                  </ol>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey='1'>
                <Accordion.Header>Safari</Accordion.Header>
                <Accordion.Body>
                  <p>
                    I det vänstra hörnet av adressfältet klicka på AA ikonen och välj Alternativ för
                    weppplatsinställningar (website settings). klicka på kamera och ändra till
                    tillåt. ladda om sidan
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
    </div>
  );
};
export default HelpOverlay;
