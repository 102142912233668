import {createRef, useRef, useEffect, useState} from 'react';
import {filter, orderBy} from 'lodash';
import {useLockBodyScroll} from '@uidotdev/usehooks';

import {isMobile} from 'react-device-detect';
import withRouter from '../global/withRouter';
import Lottie from 'lottie-react';
import {Post} from '../../data/api';
import TinderCard from 'react-tinder-card';

import TC from '../global/TC';

import done from '../../assets/range.jpg';
import logo from '../../assets/logo.json';
import logo_pic from '../../assets/logo.svg';
import rangepic from '../../assets/range.svg';
import '../../styles/range.scss';

const Range = () => {
  useLockBodyScroll();
  const [introDone, setIntroDone] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [showTC, setShowTC] = useState(false);
  const [phone, setPhone] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [allDone, setAllDone] = useState(false);

  const validateEmail = e => {
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    setEmail(e.target.value);
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const logoRef = useRef(null);
  useEffect(() => {
    if (logoRef?.current && !introDone) {
      logoRef.current.setSpeed(2);
      logoRef.current.goToAndPlay(0, true);
      setTimeout(() => {
        setIntroDone(true);
      }, 1500);
    }
  }, [logoRef]);

  return (
    <>
      <div className={isMobile ? 'logoHolder smaller' : 'logoHolder'}>
        <Lottie
          lottieRef={logoRef}
          className='logo'
          loop={false}
          autoplay={false}
          animationData={logo}
        />
      </div>
      <div className='range'>
        <div className='quizHolder'>
          <div className={'card black'}>
            <div
              className='pic end tcpic'
              style={{
                backgroundImage: 'url(' + done + ')'
              }}>
              {!allDone ? <img src={rangepic} /> : <p></p>}
            </div>

            <div className='cardQuestion'>
              {allDone && (
                <div className='alldone'>
                  <img src={logo_pic} />
                  <p>Tack!</p>
                </div>
              )}
              {!allDone && (
                <>
                  <input
                    type='text'
                    placeholder='Namn'
                    value={name}
                    className='pressable'
                    onChange={e => {
                      setName(e.target.value);
                    }}
                  />
                  <input
                    type='text'
                    placeholder='Din@epostadress.se'
                    value={email}
                    onChange={validateEmail}
                    className={!emailValid ? 'notvalid pressable' : 'pressable'}
                  />
                  <input
                    type='text'
                    className='pressable'
                    placeholder='Mobilnummer'
                    value={phone}
                    onChange={e => {
                      let str = e.target.value;
                      str = str.replace(/\D/g, '');
                      setPhone(str);
                    }}
                  />
                  <label className='checkboxHolder'>
                    <input
                      type='checkbox'
                      checked={accepted}
                      onChange={e => {
                        setAccepted(e.target.checked);
                      }}
                      className='pressable'
                    />
                    <p>
                      Jag godkänner{' '}
                      <span
                        className='subline pressable'
                        onClick={() => {
                          setShowTC(true);
                        }}>
                        tävlingsvillkoren
                      </span>{' '}
                      samt att jag vill ha nyhetsbrev.
                    </p>
                  </label>
                  <div
                    className={
                      name.length >= 2 &&
                      phone.length >= 10 &&
                      emailValid &&
                      email.length > 4 &&
                      accepted
                        ? 'btn sendit active pressable'
                        : 'btn sendit pressable'
                    }
                    onClick={() => {
                      if (
                        name.length >= 2 &&
                        phone.length >= 10 &&
                        emailValid &&
                        email.length > 4 &&
                        accepted
                      ) {
                        Post('saveShootingRange', {
                          data: {name, phone, email}
                        })
                          .then(data => {
                            setAllDone(true);
                            setName('');
                            setEmail('');
                            setPhone('');
                            setEmailValid(false);
                          })
                          .catch(err => {
                            console.log('err');
                          });
                      }
                    }}>
                    Skicka in
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {showTC && <TC openOverlay={setShowTC} id={3} />}
    </>
  );
};

export default withRouter(Range);
