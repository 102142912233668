import axios from 'axios';
import {has} from 'lodash';
import qs from 'qs';

const CONFIG = {
  host: 'localhost:3000'
};
export const Post = (endpoint, data) => {
  let host = window.location.hostname;
  if (host === 'localhost') {
    host = CONFIG.host;
  }
  host = window.location.protocol + '//' + host + '/api/';

  return axios
    .post(host + endpoint, qs.stringify(data, {parseArrays: false}))
    .then(res => {
      if (has(res, 'data')) {
        if (has(res.data, 'data')) {
          if (has(res.data.data, 'success')) {
            return Promise.resolve({
              data: res.data.data.success,
              status: res.status
            });
          }
          if (has(res.data.data, 'error')) {
            let obj = res.data.data;
            obj.status = res.status;
            return Promise.resolve(obj);
          }
        }
      }
      return Promise.resolve(res);
    })
    .catch(err => {
      return Promise.reject(err.response);
    });
};

export const Get = endpoint => {
  let host = window.location.hostname;
  if (host === 'localhost') {
    host = CONFIG.host;
  }

  host = window.location.protocol + '//' + host + '/api/';
  // {
  //     headers: {
  //       JustforTesting: 'jupp',
  //     },
  //   }
  return axios
    .get(host + endpoint)
    .then(res => {
      if (has(res, 'data')) {
        if (has(res.data, 'data')) {
          if (has(res.data.data, 'success')) {
            return Promise.resolve({
              data: res.data.data.success,
              status: res.status
            });
          }
          if (has(res.data.data, 'error')) {
            if (res.data.data.error === 'SESSION_TIMEDOUT') {
              console.log(res.data.data.error, endpoint);
            }

            return Promise.resolve({
              error: res.data.data.error,
              status: res.status
            });
          }
        }
      }
      return Promise.resolve(res);
    })
    .catch(err => {
      if (!err.response) {
        return Promise.resolve({status: 500, error: 'NO_NETWORK'});
      }
      return Promise.reject(err.response);
    });
};
