import {useState} from 'react';
import '../../../styles/qr.scss';
import {Swiper, SwiperSlide} from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-cards';
import 'bootstrap/dist/css/bootstrap.min.css';

import {EffectCards} from 'swiper/modules';
import {useNavigate} from 'react-router-dom';

const Onboarding = props => {
  const {setShow, cookiesAccepted} = props;
  const navigate = useNavigate();
  const [currIndex, setCurrIndex] = useState(0);
  return (
    <Swiper
      effect={'cards'}
      onSlideChange={e => {
        setCurrIndex(e.activeIndex);
      }}
      grabCursor={true}
      modules={[EffectCards]}
      cardsEffect={{slideShadows: false}}
      className='Onboarding'>
      <SwiperSlide key='1' className={currIndex === 0 ? 'active' : ''}>
        <img className='top_pic' src={require('../../../assets/qr/slide1.jpg')} />
        {(cookiesAccepted === 1 || cookiesAccepted === -1) && (
          <div className='slide_content'>
            <h3>
              Du måste
              <br />
              godkänna cookies!
            </h3>
            <div
              style={{marginTop: 10}}
              className='btn'
              onClick={() => {
                navigate('/');
              }}>
              Gå tillbaka
            </div>
          </div>
        )}
        {cookiesAccepted === 2 && (
          <div className='slide_content'>
            <h3>
              Var med och tävla!
              <br />
              Hitta alla QR koder.
            </h3>
            <p>
              Vi sparar dina framsteg
              <br />
              med kakor på din enhet.
            </p>
          </div>
        )}
      </SwiperSlide>
      {cookiesAccepted === 2 && (
        <>
          <SwiperSlide key='2' className={currIndex === 1 ? 'active' : ''}>
            <img className='top_pic' src={require('../../../assets/qr/slide2.jpg')} />
            <div className='slide_content'>
              <h3>QR koderna ser ut så här</h3>
              <img className='qr_tmp' src={require('../../../assets/qr/neee.png')} />
              <p>
                När du lyckats hitta
                <br />
                alla qrkoder får du fylla
                <br />i dina uppgifter!
              </p>
            </div>
          </SwiperSlide>
          <SwiperSlide key='3' className={currIndex === 2 ? 'active' : ''}>
            <img className='top_pic' src={require('../../../assets/qr/slide3.jpg')} />
            <div className='slide_content'>
              <h3>
                Skanna med kameran
                <br />
                på hemsidan
              </h3>
              <p className='small'>
                Detta fungerar bara om
                <br />
                vi fått access till din kamera!
              </p>
              <div
                className='btn'
                onClick={() => {
                  setShow(false);
                }}>
                Nu kör vi
              </div>
            </div>
          </SwiperSlide>
        </>
      )}
      )
    </Swiper>
  );
};

export default Onboarding;
