import React, {useState, useEffect} from 'react';

import withRouter from '../global/withRouter';
import {Routes, Route} from 'react-router-dom';

import Quiz from '../quiz/Quiz';
import News from '../news/News';
import Range from '../range/Range';
import Qr from '../qr/Qr';
import Policy from '../policy/Policy';
import Start from '../start/Start';

const RoutesRoot = props => {
  return (
    <>
      <Routes>
        <Route exact path='/' element={<Start />} />
        <Route exact path='/quiz' element={<Start />} />
        <Route exact path='/newsletter' element={<News />} />
        <Route exact path='/range8j2f39' element={<Start />} />
        <Route exact path='/integritetspolicy' element={<Policy />} />
        <Route exact path='/qr/:qr_code?' element={<Start />} />
      </Routes>
    </>
  );
};

export default withRouter(RoutesRoot);
