import {createRef, useRef, useEffect, useState} from 'react';
import {filter, orderBy} from 'lodash';
import {useLockBodyScroll} from '@uidotdev/usehooks';

import {isMobile} from 'react-device-detect';
import withRouter from '../global/withRouter';
import Lottie from 'lottie-react';
import {Post} from '../../data/api';
import TinderCard from 'react-tinder-card';

import TC from '../global/TC';
import quiz from '../../assets/quiz.svg';

import done from '../../assets/done.jpg';
import logo from '../../assets/logo.json';
import logo_pic from '../../assets/logo.svg';
import '../../styles/quiz.scss';

const Quiz = () => {
  useLockBodyScroll();
  const [introDone, setIntroDone] = useState(false);
  const [startQuiz, setStartQuiz] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [cid, setCid] = useState(0);

  const [questions, setQuestions] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [answers, setAnswers] = useState([-1, -1, -1]);
  const [showTC, setShowTC] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [emailValid, setEmailValid] = useState(false);
  const [allDone, setAllDone] = useState(false);
  const [correct, setCorrect] = useState(0);

  const validateEmail = e => {
    // eslint-disable-next-line
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    setEmail(e.target.value);
    if (e.target?.value && e.target.value.match(isValidEmail)) {
      setEmailValid(true);
    } else {
      setEmailValid(false);
    }
  };

  const [qRefs, setQRefs] = useState([]);
  const q = [
    {
      id: 0,
      q: 'Hur långt kan man mäta med Eliminator6 kikarsiktet?',
      options: [
        {id: 0, a: '500 m', c: 0},
        {id: 1, a: '1500 m', c: 0},
        {id: 2, a: '2000 m', c: 1}
      ],
      pic: require('../../assets/quiz/burris.jpg')
    },
    {
      id: 1,
      q: 'Vad heter Normas patronserie för ljuddämpare?',
      options: [
        {id: 0, a: 'Suppressor', c: 0},
        {id: 1, a: 'Supremer', c: 0},
        {id: 2, a: 'Silencer', c: 1}
      ],
      pic: require('../../assets/quiz/norma.jpg')
    },
    {
      id: 2,
      q: 'Vad väger ljuddämparen Ase Utra Radien Titan?',
      options: [
        {id: 0, a: '150 gram', c: 0},
        {id: 1, a: '210 gram', c: 1},
        {id: 2, a: '310 gram', c: 0}
      ],
      pic: require('../../assets/quiz/radien.jpg')
    }
    // {
    //   id: 3,
    //   q: 'Vad heter rakreptern från Chapuis?',
    //   options: [
    //     {id: 0, a: 'Bentely', c: 0},
    //     {id: 1, a: 'Rols', c: 1},
    //     {id: 2, a: 'Jaguar', c: 0}
    //   ],
    //   pic: require('../../assets/quiz/chapuis.jpg')
    // },
    // {
    //   id: 4,
    //   q: ' Vad heter Sordins nya jaktkåpor med blåtandsuppkoppling?',
    //   options: [
    //     {id: 0, a: 'R2', c: 0},
    //     {id: 1, a: 'D2', c: 0},
    //     {id: 2, a: 'X2', c: 1}
    //   ],
    //   pic: require('../../assets/quiz/sordin.jpg')
    // }
  ];

  useEffect(() => {
    let qs = orderBy(q, ['id'], ['desc']);
    let r = [];
    qs.forEach(i => {
      r.push(createRef);
    });
    setQRefs(r);
    setQuestions(qs);
  }, []);

  const logoRef = useRef(null);
  useEffect(() => {
    if (logoRef?.current && !introDone) {
      logoRef.current.setSpeed(2);
      logoRef.current.goToAndPlay(0, true);
      setTimeout(() => {
        setIntroDone(true);
      }, 1500);
    }
  }, [logoRef]);

  const onSwipe = (direction = false) => {
    if (cid !== questions.lenght - 1) {
      setCid(cid + 1);
    }
    let ans = filter(answers, i => i > -1);
    if (ans.length === questions.length) {
      setIsDone(true);
    }
  };

  // const onCardLeftScreen = (myIdentifier) => {
  //   console.log(myIdentifier + " left the screen");
  // };
  return (
    <>
      <div className={isMobile ? 'logoHolder smaller' : 'logoHolder'}>
        <Lottie
          lottieRef={logoRef}
          className='logo'
          loop={false}
          autoplay={false}
          animationData={logo}
        />
      </div>
      <div className='quiz'>
        {!startQuiz && (
          <div className='start'>
            <div className={!introDone ? 'content-holder' : 'content-holder show'}>
              <div className='content'>
                <img src={quiz} />
                <p style={{marginTop: 10}}>
                  Svara på tre frågor och vinn ett <b>Burris&nbsp;Eliminator6</b>
                </p>
                <p>
                  {' '}
                  Lägg lite tid på att hitta svaren, så kanske du blir vinnare av ett kikarsikte med
                  laseravståndsmätare och kulbanekompensator, nya linser bättre prestanda och helt
                  ny elegant design.
                </p>
                <div
                  className='btn'
                  onClick={() => {
                    setStartQuiz(true);
                  }}>
                  Starta
                </div>
              </div>
            </div>
          </div>
        )}
        {startQuiz && (
          <div className='quizHolder'>
            {questions.length > 0 &&
              !isDone &&
              questions.map((item, index) => {
                let cla = 'card';
                if (item.id !== cid) {
                  cla += ' locked';
                }
                if (item.id < cid) {
                  return null;
                }
                return (
                  <TinderCard
                    key={`question${index}`}
                    onSwipe={onSwipe}
                    swipeRequirementType={'position'}
                    swipeThreshold={isMobile ? window.innerWidth - 200 : 400}
                    ref={qRefs[item.id]}
                    style={[cid === item.id ? {zIndex: 200} : {zIndex: 1 + item.id}]}
                    preventSwipe={answers[item.id] > -1 ? [] : ['up', 'left', 'right', 'down']}
                    className={cla + ' _' + item.id}
                    //onCardLeftScreen={() => onCardLeftScreen(item.id)}
                  >
                    <div
                      className='pic'
                      style={{
                        backgroundImage: 'url(' + item.pic + ')'
                      }}>
                      <p>Fråga {item.id + 1}</p>
                    </div>
                    <div className='cardQuestion'>
                      <h2>{item.q}</h2>
                      <div className='btns'>
                        {item.options.map((o, ind) => {
                          return (
                            <div
                              key={`answer${index}_${ind}`}
                              className={
                                answers[item.id] === o.id
                                  ? 'btn selected pressable'
                                  : answers[item.id] !== -1
                                    ? 'btn gray pressable'
                                    : 'btn pressable'
                              }
                              onClick={() => {
                                let a = [...answers];
                                a[item.id] = o.id;
                                let c = correct;
                                c += o.c;
                                setCorrect(c);
                                setAnswers(a);
                              }}>
                              {o.a}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </TinderCard>
                );
              })}

            {isDone && (
              <div
                className={'card black'}
                //onCardLeftScreen={() => onCardLeftScreen(item.id)}
              >
                <div
                  className='pic end'
                  style={{
                    backgroundImage: 'url(' + done + ')'
                  }}>
                  {!allDone ? (
                    <p>
                      Fyll i dina
                      <br />
                      uppgifter
                    </p>
                  ) : (
                    <p></p>
                  )}
                </div>
                <div className='cardQuestion'>
                  {allDone && (
                    <div className='alldone'>
                      <img src={logo_pic} />
                      <p>
                        Tack! <br />
                        Du är nu med i våran tävling!
                      </p>
                    </div>
                  )}
                  {!allDone && (
                    <>
                      <input
                        type='text'
                        placeholder='Namn'
                        value={name}
                        className='pressable'
                        onChange={e => {
                          setName(e.target.value);
                        }}
                      />
                      <input
                        type='text'
                        placeholder='Din@epostadress.se'
                        value={email}
                        onChange={validateEmail}
                        className={!emailValid ? 'notvalid pressable' : 'pressable'}
                      />
                      <input
                        type='text'
                        className='pressable'
                        placeholder='Mobilnummer'
                        value={phone}
                        onChange={e => {
                          let str = e.target.value;
                          str = str.replace(/\D/g, '');
                          setPhone(str);
                        }}
                      />
                      <label className='checkboxHolder'>
                        <input
                          type='checkbox'
                          checked={accepted}
                          onChange={e => {
                            setAccepted(e.target.checked);
                          }}
                          className='pressable'
                        />
                        <p>
                          Jag godkänner{' '}
                          <span
                            className='subline pressable'
                            onClick={() => {
                              setShowTC(true);
                            }}>
                            tävlingsvillkoren
                          </span>{' '}
                          samt att jag vill ha nyhetsbrev.
                        </p>
                      </label>
                      <div
                        className={
                          name.length >= 2 &&
                          phone.length >= 10 &&
                          emailValid &&
                          email.length > 4 &&
                          accepted
                            ? 'btn sendit active pressable'
                            : 'btn sendit pressable'
                        }
                        onClick={() => {
                          if (
                            name.length >= 2 &&
                            phone.length >= 10 &&
                            emailValid &&
                            email.length > 4 &&
                            accepted
                          ) {
                            Post('saveData', {
                              data: {name, phone, email, answers, correct}
                            })
                              .then(data => {
                                setAllDone(true);
                                setAnswers([-1, -1, -1]);
                                setCorrect(0);
                                setName('');
                                setEmail('');
                                setPhone('');
                                setEmailValid(false);
                              })
                              .catch(err => {
                                console.log('err');
                              });
                          }
                        }}>
                        Skicka in
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {questions.length > 0 &&
              !isDone &&
              questions.map((item, index) => {
                if (cid !== item.id || answers[item.id] === -1) return null;
                return (
                  <div key='nextbtn' className='btn nextBtn' onClick={onSwipe}>
                    Nästa
                  </div>
                );
              })}
          </div>
        )}
      </div>
      {showTC && <TC openOverlay={setShowTC} id={1} />}
    </>
  );
};

export default withRouter(Quiz);
