import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/tc.scss';

const TC = props => {
  const {openOverlay, id} = props;

  return (
    <div className='tc'>
      <div className='tc_content'>
        <div
          className='close'
          onClick={() => {
            openOverlay(false);
          }}>
          <FontAwesomeIcon icon={faCircleXmark} size='2x' />
        </div>
        <div className='hcontent'>
          <h3>Tävlingsvillkor</h3>
          {id === 1 && (
            <ul>
              <li>Tävlingen organiseras av Outdoor Enterprise Sweden AB.</li>
              <li>
                Genom att delta i tävlingen bekräftar och godkänner du att vi publicerar ditt namn
                och tävlingsbidrag på Outdoor Enterprise Swedens sociala medier.
              </li>

              <li>
                Vinsten/priset kan inte tas ut i kontanter och kan inte heller bytas ut mot
                annan/andra produkter till ett likvärdigt pris. Eventuell vinstskatt betalas av
                vinnaren.
              </li>
              <li>
                Anställda på Outdoor Enterprise Sweden och deras närstående tillåts inte att delta i
                Outdoor Enterprise Swedens tävlingar på sociala medier.
              </li>

              <li>
                Samtliga vinnare utses av en jury ledd av Outdoor Enterprise Swedens
                marknadsavdelning eller genom omröstning på sociala medier.
              </li>
              <li>
                Vinnare kommer att kontaktas via e-post eller genom direktmeddelande på sociala
                medier. Om vinnaren inte svarar inom 30 dagar från att vinnaren har utannonserats
                eller kontaktats förbehåller vi oss rätten att utse en ny vinnare.
              </li>
              <li>
                När du deltar i en tävling godkänner du Outdoor Enterprise Swedens tävlingsregler
                och villkor.
              </li>
              <li>
                Information om hur vi hanterar personuppgifter finns i vår{' '}
                <a
                  href='https://expo.outdoor-enterprise.se/integritetspolicy'
                  target='_blank'
                  rel='noreferrer'>
                  integritetspolicy
                </a>
              </li>
              <li>
                Alla Outdoor Enterprise Swedens ABs tävlingsregler och villkor regleras i enlighet
                med svensk lagstiftning.
              </li>
            </ul>
          )}
          {id === 2 && (
            <ul>
              <li>Tävlingen organiseras av Outdoor Enterprise Sweden AB.</li>
              <li>Tävlingen är öppen för alla besökare på Swedish Game Fair 2024.</li>
              <li>
                Om tävlingar publiceras i sociala medier är denna inte på något sätt sponsrad, stödd
                eller administrerad av eller knuten till den valda sociala mediekanalen, till
                exempel Facebook eller Instagram. Bidrag som kan uppfattas stötande eller kränkande
                kan komma att tas bort utan förvarning.
              </li>
              <li>
                Genom att delta i tävlingen bekräftar och godkänner du att vi publicerar ditt namn
                och tävlingsbidrag på Outdoor Enterprise Swedens sociala medier.
              </li>

              <li>
                Vinsten/priset kan inte tas ut i kontanter och kan inte heller bytas ut mot
                annan/andra produkter till ett likvärdigt pris. Eventuell vinstskatt betalas av
                vinnaren.
              </li>
              <li>
                Anställda på Outdoor Enterprise Sweden och deras närstående tillåts inte att delta i
                Outdoor Enterprise Swedens tävlingar.
              </li>

              <li>
                Samtliga vinnare utses av en jury ledd av Outdoor Enterprise Swedens
                marknadsavdelning eller genom omröstning på sociala medier.
              </li>
              <li>
                Vinnare kommer att kontaktas via e-post eller genom direktmeddelande på sociala
                medier. Om vinnaren inte svarar inom 30 dagar från att vinnaren har utannonserats
                eller kontaktats förbehåller vi oss rätten att utse en ny vinnare.
              </li>
              <li>
                När du deltar i en tävling godkänner du Outdoor Enterprise Swedens tävlingsregler
                och villkor.
              </li>
              <li>
                Information om hur vi hanterar personuppgifter finns i vår{' '}
                <a
                  href='https://expo.outdoor-enterprise.se/integritetspolicy'
                  target='_blank'
                  rel='noreferrer'>
                  integritetspolicy
                </a>
              </li>
              <li>
                Alla Outdoor Enterprise Swedens ABs tävlingsregler och villkor regleras i enlighet
                med svensk lagstiftning.
              </li>
            </ul>
          )}
          {id === 3 && (
            <ul>
              <li>Tävlingen organiseras av Outdoor Enterprise Sweden AB.</li>
              <li>Tävlande måste ha jägarexamen inklusive godkänt praktiskt högviltsskytteprov.</li>

              <li>
                Genom att delta i tävlingen bekräftar och godkänner du att vi publicerar ditt namn
                och tävlingsbidrag på Outdoor Enterprise Swedens sociala medier.
              </li>

              <li>
                Vinsten/priset kan inte tas ut i kontanter och kan inte heller bytas ut mot
                annan/andra produkter till ett likvärdigt pris. Eventuell vinstskatt betalas av
                vinnaren.
              </li>

              <li>
                Anställda på Outdoor Enterprise Sweden och deras närstående tillåts inte att delta i
                Outdoor Enterprise Swedens tävlingar på sociala medier.
              </li>

              <li>
                Samtliga vinnare utses av en jury ledd av Outdoor Enterprise Swedens
                marknadsavdelning eller genom omröstning på sociala medier.
              </li>

              <li>
                Vinnare kommer att kontaktas via e-post eller genom direktmeddelande i på social
                medier. Om vinnaren inte svarar inom 30 dagar från att vinnaren har utannonserats
                eller kontaktats förbehåller vi oss rätten att utse en ny vinnare.
              </li>

              <li>
                När du deltar i en tävling godkänner du Outdoor Enterprise Swedens tävlingsregler
                och villkor.
              </li>

              <li>
                Information om hur vi hanterar personuppgifter finns i vår{' '}
                <a
                  href='https://expo.outdoor-enterprise.se/integritetspolicy'
                  target='_blank'
                  rel='noreferrer'>
                  integritetspolicy
                </a>
              </li>

              <li>
                Alla Outdoor Enterprise Swedens ABs tävlingsregler och villkor regleras i enlighet
                med svensk lagstiftning.
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
export default TC;
